import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { CommandBarButton, IconButton, Dialog, DefaultButton, DialogType, Stack } from "@fluentui/react";
import {  SquareFilled, ShieldLockRegular, ErrorCircleRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import { isEmpty } from "lodash-es";
import DOMPurify from 'dompurify';

import styles from "./Chat.module.css";
import Contoso from "../../assets/Contoso.svg";
import { XSSAllowTags } from "../../constants/xssAllowTags";

//reaptcha implementation
import ReCAPTCHA from 'react-google-recaptcha'


import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    submit_captcha,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    Conversation,
    historyGenerate,
    historyUpdate,
    historyClear,
    ChatHistoryLoadingState,
    CosmosDBStatus,
    ErrorMessage,
    frontendSettings
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { ExampleList } from "../../components/Example";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { trackPageView,trackException ,trackTrace} from "../../appinsight";



const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}



const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean | undefined>();
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
    const [disclaimerDialogState, setDisclaimerDialogState] = useState<boolean>(false);

    const RECAPTCHA_ENABLED = (window as any).REACT_APP_RECAPTCHA_ENABLED;  
    const [isCaptchaSuccessful, setIsCaptchaSuccess] =  useState(false);

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    // Manually call trackPageView to establish the current user/session/pageview 
    trackPageView("Main page");
    
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]
    const NO_CONTENT_ERROR = "No content in messages object."

    useEffect(() => {     
        if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working  
            && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
            && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail 
            && hideErrorDialog) {
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Chat history is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    useEffect(() => {
        setIsLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
    }, [appStateContext?.state.chatHistoryLoadingState])

    const getUserInfoList = async () => {
        if (!AUTH_ENABLED) {
            setShowAuthMessage(false);
            return;
        }
        const userInfoList = await getUserInfo();
        if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1") {
            setShowAuthMessage(true);
        }
        else {
            setShowAuthMessage(false);
        }
    }

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent

            if (resultMessage.context) {
                toolMessage = {
                    id: uuid(),
                    role: TOOL,
                    content: resultMessage.context,
                    date: new Date().toISOString(),
                }
            }
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };

        let result = {} as ChatResponse;
        try {
            const response = await conversationApi(request, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                                        setShowLoadingMessage(false);
                                    }
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId);
                                    })
                                }
                                else if (result.error) {
                                    throw Error(result.error);
                                }
                                runningText = "";
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                        }
                    });
                }
                conversation.messages.push(toolMessage, assistantMessage)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, toolMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const makeApiRequestWithCosmosDB = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        //api call params set here (generate)
        let request: ConversationRequest;
        let conversation;
        if (conversationId) {
            conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if (!conversation) {
                // IF not chat history found, then use the current chat
                conversation = appStateContext?.state?.currentChat
                if (!conversation) {
                    console.error("Conversation not found.");
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                else{
                    conversation.messages.push(userMessage);
                    request = {
                        messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
                    };
                    setMessages(request.messages)
                }              
                
            } else {
                conversation.messages.push(userMessage);
                request = {
                    messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
                };
               
            }
        } else {
            request = {
                messages: [userMessage].filter((answer) => answer.role !== ERROR)
            };
            setMessages(request.messages)
        }
        let result = {} as ChatResponse;
        try {
            const response = conversationId ? await historyGenerate(request, abortController.signal, conversationId) : await historyGenerate(request, abortController.signal);
            if (!response?.ok) {                
                const responseJson = await response.json();              

                trackException( new Error(responseJson.error));
                var errorResponseMessage = responseJson.error === undefined ? "There was an error generating a response to your question. Please try again or come back later." : responseJson.error;

                if (response.status === 429) {
                    const retryAfterSeconds = responseJson.error.match(/(\d+) seconds/)[1];                     
                    errorResponseMessage = `Due to the current high volume of usage, the ITA Global Business Navigator BETA is temporarily unable to respond. Please wait ${retryAfterSeconds} seconds and try again.`;
                }                
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: `${errorResponseMessage}`,
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        resultConversation = appStateContext?.state?.currentChat
                        if (!resultConversation) {
                            console.error("Conversation not found.");
                            setIsLoading(false);
                            setShowLoadingMessage(false);
                            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                            return;
                        }
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    setMessages([...messages, userMessage, errorChatMsg])
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...resultConversation.messages]);
                return;
            }
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (!result.choices?.[0]?.messages?.[0].content) {
                                    errorResponseMessage = NO_CONTENT_ERROR;
                                    throw Error();
                                }
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                                        setShowLoadingMessage(false);
                                    }
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId);
                                    })
                                }
                                runningText = "";
                            }
                            else if (result.error) {
                                throw Error(result.error);
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                         }
                    });
                }

                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        resultConversation = appStateContext?.state?.currentChat;
                        if (!resultConversation)  {
                            console.error("Conversation not found.");
                            setIsLoading(false);
                            setShowLoadingMessage(false);
                            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                            return;
                        }                        
                    }
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                } else {
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                }
                if (!resultConversation) {
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                isEmpty(toolMessage) ?
                    setMessages([...messages, assistantMessage]) :
                    setMessages([...messages, toolMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = `An error occurred. ${errorResponseMessage}`;
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                let resultConversation;
                if (conversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                } else {
                    if (!result.history_metadata) {
                        console.error("Error retrieving data.", result);
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: errorMessage,
                            date: new Date().toISOString()
                        } 
                        setMessages([...messages, userMessage, errorChatMsg])
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    resultConversation.messages.push(errorChatMsg);
                }
                if (!resultConversation) {
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();

    }

    const onExampleClicked = (example: string) => {
        makeApiRequestWithCosmosDB(example, appStateContext?.state.currentChat?.id);
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        console.log("key up: " + event.key )
        if (event.key == "Enter") {
            makeApiRequestWithCosmosDB(event.currentTarget.innerText, appStateContext?.state.currentChat?.id);
        }
    };


    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const submitCAPTCHA=async ()=> {
        
        if (recaptchaRef.current)  {
            const captchaValue = recaptchaRef.current?.getValue()

            if (!captchaValue) {
                alert('Please verify the reCAPTCHA!')
                window.location.reload();
            } else {
                // make form submission
                setIsCaptchaSuccess(true);
                const response = await submit_captcha(captchaValue)
                const result = await response.json();                   
                if(result.success){ 
                    setDisclaimerDialogState(true);
                }else {
                    alert('reCAPTCHA validation failed!')
                    window.location.reload();
                  }                
            }
        }    
    }
    const onDisclaimerContinueClicked=()=>{       
        
        setDisclaimerDialogState(true);
    }
    const onDisclaimerCancelClicked=()=>{ 
        window.location.href = 'https://www.trade.gov/';
    }

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {

            //Remove the past messages from the history object
            if (appStateContext?.state?.currentChat) {
                appStateContext.state.currentChat.messages = [];  
            }

            let response = await historyClear(appStateContext?.state.currentChat.id)
            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {               
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state?.currentChat?.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state?.currentChat });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        setClearingChat(false)
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done)
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            const response = await historyUpdate(messages, id)
            return response
        }

        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
                if (!appStateContext?.state.currentChat?.messages) {
                    console.error("Failure fetching current chat state.")
                    return
                }
                const noContentError = appStateContext.state.currentChat.messages.find(m => m.role === ERROR)
                
                if (!noContentError?.content.includes(NO_CONTENT_ERROR)) {
                    saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
                        .then((res) => {
                            if (!res.ok) {
                                let errorMessage = "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
                                let errorChatMsg: ChatMessage = {
                                    id: uuid(),
                                    role: ERROR,
                                    content: errorMessage,
                                    date: new Date().toISOString()
                                }
                                if (!appStateContext?.state.currentChat?.messages) {
                                    let err: Error = {
                                        ...new Error,
                                        message: "Failure fetching current chat state."
                                    }
                                    throw err
                                }
                                setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                            }
                            return res as Response
                        })
                        .catch((err) => {
                            console.error("Error: ", err)
                            let errRes: Response = {
                                ...new Response,
                                ok: false,
                                status: 500,
                            }
                            return errRes;
                        })
                }
            } else {
            }
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useEffect(() => {
        if (AUTH_ENABLED !== undefined) getUserInfoList();
    }, [AUTH_ENABLED]);

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation(citation);
        setIsCitationPanelOpen(true);
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }

    return (
        <div className={styles.container} role="main">
            {showAuthMessage ? (
                <Stack className={styles.chatEmptyState}>
                    <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "200px", width: "200px" }} />
                    <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
                    <h2 className={styles.chatEmptyStateSubtitle}>
                        This app does not have authentication configured. Please add an identity provider by finding your app in the <a href="https://portal.azure.com/" target="_blank">Azure Portal</a> 
                        and following <a href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization" target="_blank">these instructions</a>.
                    </h2>
                    <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>Authentication configuration takes a few minutes to apply. </strong></h2>
                    <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong></h2>
                </Stack>
            ) : (
                <Stack horizontal className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!messages || messages.length < 1 ? (
                            <Stack className={styles.chatEmptyState}>
                                <img
                                    src={ui?.chat_logo ? ui.chat_logo : Contoso}
                                    className={styles.chatIcon}
                                    aria-label={ui?.chat_title}
                                    alt={ui?.chat_title}
                                />
                                 <Stack horizontal verticalAlign="center">
                                    <h1 className={styles.chatEmptyStateTitle}>{ui?.chat_title}</h1>{ui?.show_beta_title && (
                                        <sup className={styles.disclaimerBeta}>{ui?.beta_title}</sup>
                                    )} 
                                </Stack>
                                {/* <h2 className={styles.chatEmptyStateSubtitle}>{ui?.chat_description}</h2> */}
                                <h2 className={styles.chatEmptyStateSubtitle} dangerouslySetInnerHTML={{__html: ui?.chat_description ?? ''}}/>
                                <div className={styles.navbar}>
                                    <a href="https://www.trade.gov/contact-us">Contact Us</a>
                                    <a href="https://www.trade.gov/privacy-program" target="_blank">Privacy Program</a>
                                    <a href="https://www.trade.gov/chatbot-disclaimer" target="_blank">Chatbot Disclaimer</a>
                                    <a href="https://www.trade.gov/endorsement-disclaimer" target="_blank">Website Disclaimer</a>
                                    <a href="https://www.trade.gov/sites/default/files/2021-02/ITA%20Quality%20Assurance%20Statement.pdf" target="_blank">Information Quality Guideliness</a>
                                    <a href="https://www.trade.gov/node/14423" target="_blank">Accessibility</a>
                                </div>

                                <Stack className={styles.chatEmptyStateSuggestions}>
                                    <span className={styles.suggestionTitle}>
                                        <h2 className={styles.chatTrySuggestions}>Try a suggestion:</h2>
                                    </span>
                                <ExampleList onExampleClicked={onExampleClicked} onKeyUp={onKeyUp} />
                                </Stack>

                            </Stack>
                            
                        ) : (
                            <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                {messages.map((answer, index) => (
                                    <>
                                        {answer.role === "user" ? (
                                            <div className={styles.chatMessageUser} tabIndex={0}>
                                                <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                            </div>
                                        ) : (
                                            answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    answer={{
                                                        answer: answer.content,
                                                        citations: parseCitationFromMessage(messages[index - 1]),
                                                        message_id: answer.id,
                                                        feedback: answer.feedback
                                                    }}
                                                    onCitationClicked={c => 
                                                        // Uncomment the below line to show the citation panel
                                                        //onShowCitation(c)
                                                        c.url && !c.url.includes("blob.core") ? onViewSource(c) : null
                                                    }
                                                />
                                            </div> : answer.role === ERROR ? <div className={styles.chatMessageError}>
                                                <Stack horizontal className={styles.chatMessageErrorContent}>
                                                    <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                    <span>Error</span>
                                                </Stack>
                                                <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                            </div> : null
                                        )}
                                    </>
                                ))}
                                {showLoadingMessage && (
                                    <>
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer: "Generating answer...",
                                                    citations: []
                                                }}
                                                onCitationClicked={() => null}
                                            />
                                        </div>
                                    </>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <Stack horizontal className={styles.chatInput}>
                            {isLoading && messages.length>0 && (
                                <Stack
                                    horizontal
                                    className={styles.stopGeneratingContainer}
                                    role="button"
                                    aria-label="Stop generating"                                    
                                    tabIndex={0}
                                    onClick={stopGenerating}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                >
                                    <SquareFilled className={styles.stopGeneratingIcon}  filled={true}/>
                                    <span className={styles.stopGeneratingText}  title="Stop generating">Stop generating</span>
                                </Stack>
                            )}
                            <Stack>
                                {/* {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                            color: "#BDBDBD !important"
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)"
                                        },
                                        rootDisabled: {
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={styles.newChatIcon}
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={newChat}
                                    disabled={disabledButton()}
                                    aria-label="start a new chat button"
                                />} */}
                                <CommandBarButton
                                    role="button"
                                    styles={{
                                        icon: {
                                            color: '#FFFFFF',
                                        },
                                        iconDisabled: {
                                             color: "#BDBDBD !important",
                                            
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                                        },
                                        rootDisabled: {
                                            
                                            background: "#F0F0F0"
                                        }
                                    }}
                                    className={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? styles.clearChatBroom : styles.clearChatBroomNoCosmos}
                                    iconProps={{ iconName: 'Broom', color: '#FFFFFF'}}
                                    onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? clearChat : newChat}
                                    disabled={disabledButton()}
                                    aria-label="clear chat button"
                                    title="Clear Chat"
                                    
                                />
                                <Dialog
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                >
                                </Dialog>
                            </Stack>
                            <QuestionInput
                                clearOnSend
                                placeholder={ui?.question_input_placeholder}
                                disabled={isLoading}
                                onSend={(question, id) => {
                                    appStateContext?.state.isCosmosDBAvailable?.cosmosDB ? makeApiRequestWithCosmosDB(question, id) : makeApiRequestWithoutCosmosDB(question, id)
                                }}
                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                            />
                        </Stack>
                        <Dialog
                            /* 
                            This is the diclaimer dialog. It is shown when the user first enters the chatbot.
                             */
                            hidden={disclaimerDialogState} 
                            onDismiss={()=>{}}                                                                                                    
                            styles={{
                    
                                main: [{
                                    selectors: {
                                      ['@media (min-width: 900px)']: {
                                        maxWidth: '900px',
                                        background: "#FFFFFF",
                                        boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                        borderRadius: "8px",                                        
                                        minHeight: '800px',
                                      }
                                    }
                                  }]
                            }}
                        >
                            <Stack horizontal verticalAlign="center">
                                <img
                                    src={ui?.logo ? ui.logo : Contoso}
                                    className={styles.headerIcon}
                                    aria-title={ui?.title}
                                    alt={ui?.title}
                                />
                                 
                                <h1 className={styles.headerTitle}>{ui?.chat_title}</h1>{ui?.show_beta_title && (
                                    <sup className={styles.disclaimerBeta}>{ui?.beta_title}</sup>
                                )} 

                                {/* {ui?.show_beta_title && (
                                    <h3 className={styles.headerBeta}>{ui?.beta_title}</h3>
                                )} */}
                                {/* <IconButton
                                    className={styles.cancel}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Dismiss"
                                    title="Dismiss"
                                    onClick={()=>onDisclaimerCancelClicked()}/> */}
                            </Stack>
                            <Stack>
                                <div className={styles.disclaimerSectionTitle}>
                                Welcome to the Global Business Navigator, an artificial intelligence (AI) Chatbot from the International Trade Administration (ITA). This tool, currently in beta version testing, is designed to provide general information on the exporting process and the resources available to assist new and experienced U.S. exporters. The Chatbot, developed using Microsoft’s Azure AI services, is trained on ITA’s export-related content and aims to quickly get users the information they need. The Chatbot is intended to make the benefits of exporting more accessible by understanding non-expert language, idiomatic expressions, and foreign languages.
                                </div>                              
                            
                                <div className={styles.disclaimerSectionTitle}>
                                    <h3>Limitations</h3>                                    
                                    As a beta product, the Chatbot is currently being tested and its responses may occasionally produce inaccurate or incomplete information. The Chatbot is trained to decline out of scope or inappropriate requests. The Chatbot’s knowledge is limited to the public information on the<a href="https://www.trade.gov/export-solutions" target="_blank">Export Solutions web pages</a> of Trade.gov, which covers a wide range of topics on exporting. While it cannot provide responses specific to a company’s product or a specific foreign market, its reference pages will guide you to other relevant government resources and market research. Always double-check the Chatbot’s responses using the provided references or by visiting the<a href="https://www.trade.gov/export-solutions" target="_blank">Export Solutions web pages</a> on Trade.gov. Do not use its responses as legal or professional advice. Inaccurate advice from the Chatbot would not be a defense to violating any export rules or regulations.
                                </div>
                                <div className={styles.disclaimerSectionTitle}>
                                    <h3>Privacy</h3>
                                    The Chatbot does not collect information about users and does not use the contents of users’ chat history to learn new information. All feedback is anonymous. Please do not enter personally identifiable information (PII), sensitive, or proprietary information into the Chatbot. Your conversations will not be connected to other interactions or accounts with ITA. Conversations with the Chatbot may be reviewed to help ITA improve the tool and address harmful, illegal, or otherwise inappropriate questions.
                                </div>
                                <div className={styles.disclaimerSectionTitle}>
                                    <h3>Translation</h3>
                                    The Chatbot supports a wide range of languages. Because the Chatbot is trained in English and responses are translated, you should verify the translation. For example, the Chatbot may have difficulty with acronyms, abbreviations, and nuances in a language other than English.
                                </div>
                                <div className={styles.disclaimerFooterTitle}>
                                    <b>Responses may vary in accuracy or completeness. Please verify answers on the<a href="https://www.trade.gov/export-solutions">Export Solutions web pages</a> on Trade.gov for confirmation.</b>
                                </div>                                
                                <div className={styles.navbar}>
                                    <a href="https://www.trade.gov/contact-us" >Contact Us</a>
                                    <a href="https://www.trade.gov/privacy-program" target="_blank">Privacy Program</a>
                                    <a href="https://www.trade.gov/chatbot-disclaimer" target="_blank">Chatbot Disclaimer</a>
                                    <a href="https://www.trade.gov/endorsement-disclaimer" target="_blank">Website Disclaimer</a>
                                    <a href="https://www.trade.gov/sites/default/files/2021-02/ITA%20Quality%20Assurance%20Statement.pdf" target="_blank">Information Quality Guideliness</a>
                                    <a href="https://www.trade.gov/node/14423" target="_blank">Accessibility</a>                                    
                                </div>
                            </Stack>
                            <Stack verticalAlign="center" className={styles.recaptcha}>
                                {RECAPTCHA_ENABLED==='True' && (                                    
                                    <ReCAPTCHA 
                                        sitekey= {(window as any).REACT_APP_RECAPTCHA_SITE_KEY}// {appStateContext?.state.frontendSettings?.recaptcha_site_key}
                                        ref={recaptchaRef}
                                        onChange={() => setIsCaptchaSuccess(true)}
                                        onExpired={() => setIsCaptchaSuccess(false)}    
                                    />     
                                )}                
                                <Stack horizontal verticalAlign="center" className={styles.buttonContainer}>                                
                                    <DefaultButton ariaLabel="Return to site" title="Return to site" className ={styles.disabled} onClick={()=>onDisclaimerCancelClicked()}>Cancel</DefaultButton>
                                    
                                    {RECAPTCHA_ENABLED==='True' && !isCaptchaSuccessful && (
                                       <DefaultButton disabled={!isCaptchaSuccessful} ariaLabel="Go to chatbot" title="Go to chatbot" className ={styles.captchaDisabled} onClick={()=>submitCAPTCHA()} >Continue</DefaultButton>
                                    )}

                                    {RECAPTCHA_ENABLED ==='True' && isCaptchaSuccessful && (
                                       <DefaultButton disabled={!isCaptchaSuccessful} ariaLabel="Go to chatbot" title="Go to chatbot" className ={styles.standard} onClick={()=>submitCAPTCHA()} >Continue</DefaultButton>
                                    )}

                                    {RECAPTCHA_ENABLED ==='False' && !isLoading &&  (
                                    //    <DefaultButton ariaLabel="Go to chatbot" title="Go to chatbot" className ={styles.standard} onClick={()=>onDisclaimerContinueClicked()} >Continue</DefaultButton>
                                       <DefaultButton disabled={false} ariaLabel="Go to chatbot" title="Go to chatbot" className ={styles.standard} onClick={()=>onDisclaimerContinueClicked()} >Continue</DefaultButton>
                                    )}

                                    {RECAPTCHA_ENABLED==='False' && isLoading &&  (
                                       <DefaultButton ariaLabel="Go to chatbot" title="Go to chatbot" className ={styles.captchaDisabled} onClick={()=>onDisclaimerContinueClicked()} >Continue</DefaultButton>
                                    )}

                                </Stack>
                            </Stack>
                        </Dialog>
                    </div>
                    {/* Citation Panel */}
                    {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                            <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                <span aria-label="Citations" className={styles.citationPanelHeader}>Citations</span>
                                <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
                            </Stack>
                            <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title?.replaceAll("-"," ")}</h5>
                            <div tabIndex={0}>
                                <ReactMarkdown
                                    linkTarget="_blank"
                                    className={styles.citationPanelContent}
                                    children={DOMPurify.sanitize(activeCitation.content, {ALLOWED_TAGS: XSSAllowTags})}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                        </Stack.Item>
                    )}
                    {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel />}
                </Stack>
            )}
        </div>
    );
};

export default Chat; 

