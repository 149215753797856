import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import Contoso from "../../assets/Contoso.svg";
import { BarcodeScanner20Filled, CopyRegular } from "@fluentui/react-icons";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { CosmosDBStatus } from "../../api";
import USFlagMini from "../../assets/uswds/img/us_flag_small.png";
import DotGov from "../../assets/uswds/img/icon-dot-gov.svg";
import HttpsImage from "../../assets/uswds/img/icon-https.svg";  
 

const Layout = () => {
   
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Share");
    const [hideHistoryLabel, setHideHistoryLabel] = useState<string>("Hide chat history");
    const [showHistoryLabel, setShowHistoryLabel] = useState<string>("Show chat history");
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    function showPanel(event) {

        //Get the button that was clicked
        const element = event?.target;
        const isOpen = element.getAttribute("aria-expanded") === "true";
        element.setAttribute("aria-expanded", !isOpen);

        //Now hide the relevant div tag below

        const panel = document.getElementById('gov-banner-default');
        const isHidden = panel?.getAttribute('hidden') !== null; 
        // Toggle the hidden attribute
        if (!isOpen) { 
            panel?.removeAttribute('hidden'); 
        } 
        else { 
            panel?.setAttribute('hidden', "true"); 
        }

      }

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 480) {
            setShareLabel(undefined)
            setHideHistoryLabel("Hide history")
            setShowHistoryLabel("Show history")
          } else {
            setShareLabel("Share")
            setHideHistoryLabel("Hide chat history")
            setShowHistoryLabel("Show chat history")
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className={styles.layout}>
             {/* The following <section> tag is added to display the US government banner*/}
            <section
                className="usa-banner"
                aria-label="Official website of the United States government">
                <div className="usa-accordion">
                    <header className="usa-banner__header">
                        <div className="usa-banner__inner">
                            <div className="grid-col-auto">
                                <img
                                    aria-hidden="true"
                                    className="usa-banner__header-flag"
                                    src={USFlagMini}
                                    alt=""
                                />
                            </div>
                            <div className="grid-col-fill tablet:grid-col-auto" aria-hidden="true">
                                <p className="usa-banner__header-text">
                                    An official website of the United States government
                                </p>
                                <p className="usa-banner__header-action">Here’s how you know</p>
                            </div>
                            <button
                                
                                onClick={(event) => {showPanel(event) }}
                                type="button"
                                className="usa-accordion__button usa-banner__button"
                                aria-expanded="false"
                                aria-controls="gov-banner-default" >
                                    <span className="usa-banner__button-text">Here’s how you know</span>
                            </button>
                        </div>
                    </header>
                    <div
                    className="usa-banner__content usa-accordion__content"
                    id="gov-banner-default" hidden>
                        <div className="grid-row grid-gap-lg">
                            <div className="usa-banner__guidance tablet:grid-col-6">
                                <img
                                    className="usa-banner__icon usa-media-block__img"
                                    src={DotGov}
                                    role="img"
                                    alt=""
                                    aria-hidden="true"
                                />
                                <div className="usa-media-block__body">
                                    <p>
                                    <strong>Official websites use .gov</strong><br />A
                                    <strong>.gov</strong> website belongs to an official government
                                    organization in the United States.
                                    </p>
                                </div>
                            </div>
                            <div className="usa-banner__guidance tablet:grid-col-6">
                                <img
                                    className="usa-banner__icon usa-media-block__img"
                                    src={HttpsImage}
                                    role="img"
                                    alt=""
                                    aria-hidden="true"
                                />
                                <div className="usa-media-block__body">
                                    <p>
                                        <strong>Secure .gov websites use HTTPS</strong><br />A
                                        <strong> lock</strong> ( 
                                        <span className="icon-lock"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="52"
                                            height="64"
                                            viewBox="0 0 52 64"
                                            className="usa-banner__lock-image"
                                            role="img"
                                            aria-labelledby="banner-lock-description-default"
                                            focusable="false">
                                                <title id="banner-lock-title-default">Lock</title>
                                                <desc id="banner-lock-description-default">Locked padlock icon</desc>
                                                <path
                                                    fill="#000000"
                                                    fill-rule="evenodd"
                                                    d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                                                />
                                            </svg> 
                                        </span> ) or <strong>https://</strong> means you’ve safely connected to
                                                                    the .gov website. Share sensitive information only on official,
                                                                    secure websites.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                        <img
                            src={ui?.logo ? ui.logo : Contoso}
                            className={styles.headerIcon}
                            aria-label={ui?.title}
                            alt={ui?.title}
                        />
                        <Link to="https://www.trade.gov/" target="_blank" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>{ui?.title}</h1>
                        </Link>
                        {ui?.show_beta_title && (
                            <h3 className={styles.headerBeta}>{ui?.beta_title}</h3>
                        )}
                    </Stack>
                    {/* 
                    ----------------------------------------------------------------
                    UNCOMMENT THE FOLLOWING CODE TO ENABLE SHARE AND HISTORY BUTTONS
                    ----------------------------------------------------------------
                    <Stack horizontal tokens={{ childrenGap: 4 }} className={styles.shareButtonContainer}>
                        {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                            <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel} />
                        }
                        {ui?.show_share_button &&<ShareButton onClick={handleShareClick} text={shareLabel} />}
                    </Stack> */}
                </Stack>
            </header>
           
            <Outlet />
            <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog>
            <Stack horizontal className={styles.answerFooter}>
                <Stack.Item className={styles.answerDisclaimerContainer}>
                    {/* <span className={styles.answerDisclaimer}>{ui?.footer_disclaimer}</span> */}
                    <span className={styles.answerDisclaimer} dangerouslySetInnerHTML={{__html: ui?.footer_disclaimer ?? ''}} />
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default Layout;
